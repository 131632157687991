import
    {
        Router,
        ActivatedRouteSnapshot,
        RouterStateSnapshot,
        CanActivate,
    } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate
{
    userProfile:any;
    constructor(private router: Router, private authenticationService: AuthenticationService) { }

     canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        if (this.authenticationService.isAuthenticated()) { 
            let url=state.url.split('/').splice(1, 2)
            
            localStorage.setItem('pageUrl',JSON.stringify(url))
            return true; 
        }
        //this.router.navigate(['eligibility-verification'], { queryParams: { redirect: state.url }, replaceUrl: true });
        this.authenticationService.login();
        
        return false;

        // const token: any = this.authenticationService.tokenValue;
        // this.userProfile= JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER));
       
        // if (token && this.userProfile.isBaaSigned && this.userProfile.isPaymentProceed)
        // {
        //     return true;
        // }
        // else if (token && next?.url[0]?.path == "baa" && (!this.userProfile.isBaaSigned || !this.userProfile.isPaymentProceed))
        // {
        //     return true;
        // }
        // this.authenticationService.logout();
        // return false;
    }
}
