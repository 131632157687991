import { environment } from "src/environments/environment";
export const ENDPOINT = {
    IDENTITY_SERVICE: `${environment.identityServiceUrl}`,
    GENERAL_SERVICE: `${environment.dentaFlowUrl}/api`,
    ELIGIBILITY_SERVICE: `${environment.productApiUrl}`,
};

export const CONTROLLER = {
    AUTHENTICATION: 'authentication',
    DROPDOWN: 'dropdown',
    SETUP: 'setup',
    DATA_FROM_PMS: 'dataFromPms',
    WRITE_TO_PMS: 'writeToPms',
    CLIENT: 'client',
    PRACTICE_LOCATION: 'practiceLocation',
    PRACTICE_USER: 'practiceUser',
    DATABASE: 'database',
    DATA_SOURCE_CONNECTION: 'dataSourceConnection',
    APPLICATION_ROLE: 'applicationRole',
    USER_ROLE: 'userRole',
    MENUS: 'menus',
    USERS: 'user',
    SOURCE_TYPE: 'sourceType',
    SOURCE_MASTER: 'sourceMaster',
    SOURCE_VERSION: 'sourceVersion',
    PAYOR_MASTER: 'payorMaster',
    PAYOR_CREDENTIALS: 'payorCredentials',
    PAYOR_TAG_MAPPING: 'payorTagMapping',
    CLEARING_HOUSE_MASTER: 'clearingHouseMaster',
    CLEARING_HOUSE_CREDENTIALS: 'clearingHouseCredentials',
    CLEARING_HOUSE_TAG_MAPPING: 'clearingHouseTagMapping',
    DATA_SOURCE: 'dataSource',
    DATA_CONTEXT: 'dataContext',
    CHILD_DATA_CONTEXT_MAPPING: 'childDataContextMapping',
    PHI: 'phi',
    SOURCE_FIELD: 'sourceField',
    DESTINATION_FIELDS: 'destinationField',
    COLUMN_MAPPING: 'columnMapping',
    DATA_EXTRACTION_FREQUENCY: 'dataExtractionFrequency',
    EXTRACTION_SCHEDULE: 'extractionSchedule',
    STATE: 'state',
    TAX_ID_MASTER: 'taxIdMaster',
    TAX_ID_USER_MAPPING: 'taxIdUserMapping',
    CONFIGURATION: 'configuration',
    EXTRACTOR: 'extractor',
    WRITE_BACK: 'writeBack',
    PRODUCT_CATEGORY: 'productCategory',
    PRODUCT_COMPONENT: 'productComponent',
    PRODUCT_SUBSCRIPTION: 'productSubscription',
    TARIFF: 'tariff',
    BAA: 'ClientBaaHistory',
    SCHEDULER: 'scheduler',
    VERIFICATION: 'Verification',
    MANUAL_VERIFICATION: 'ManualVerification',
    TEMPLATE: 'Template',
    COMMON: 'common',
    FILE: 'File',
    SETTINGS: 'Settings',
    RCM_DASHBOARD:'RcmDashboard',
    SIGN_UP:'SignUp',
    VOICE:'Voice',
    ELIGIBILITY: 'Eligibility'
};

export const API = {
    AUTH: {
        SIGN_IN: `${ ENDPOINT.IDENTITY_SERVICE }/Login`,
        REFRESH: `${ ENDPOINT.IDENTITY_SERVICE }/Login/Refresh`,
        SIGN_UP: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.AUTHENTICATION }/signUp`,
        SETUP_PASSWORD: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.AUTHENTICATION }/setupPassword`,
        RESET_PASSWORD: `${ ENDPOINT.IDENTITY_SERVICE }/${ CONTROLLER.SIGN_UP }/resetPassword`,
        FORGOT_PASSWORD: `${ ENDPOINT.IDENTITY_SERVICE}/${ CONTROLLER.AUTHENTICATION }/forgotPassword`,
        FORGOT_EMAIL: `${ ENDPOINT.IDENTITY_SERVICE }/${ CONTROLLER.AUTHENTICATION }/forgotEmail`,
        SEND_VERIFICATION_CODE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.AUTHENTICATION}/sendVerificationCode`,
    },
    SETUP: {
        USER: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USERS}/getCurrentUserDetails`,
        MENUS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/getActiveProductMenus`,
        LOCATIONS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/getLocations`,
        PRODUCTS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/getSubscriptions`,
        LOCATIONS_HISTORY: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/userLocationPreferenceSave`,
    },
    GRID: {
        GET: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/UserGridPreferenceGet`,
        SET: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/UserGridPreferenceSave`,
        UPDATE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SETUP}/userGridPreferenceUpdate`,
    },
    DROPDOWN: {
        CHILD_DATA_CONTEXTS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getChildDataContexts`,
        CLEARING_HOUSES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getClearingHouses`,
        CLIENTS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getClients`,
        CLIENT_TYPES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getClientTypes`,
        CLINIC_SERVERS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getClinicServers`,
        CONNECTION_TYPES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getConnectionTypes`,
        COUNTRIES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getCountries`,
        DATA_CONTEXTS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getDataContexts`,
        JOB_FREQUENCIES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getJobFrequencies`,
        MENUS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getMenus`,
        PAYORS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getPayors`,
        PAYOR_TYPES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getPayorTypes`,
        PMS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getPMS`,
        PMS_SERVERS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getPMSServers`,
        PRACTICE_LOCATIONS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getPracticeLocations`,
        PRACTICES_BY_USERID: `${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.DROPDOWN }/getPracticeByUserId`,
        PRACTICES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getPractices`,
        PRODUCT_COMPONENTS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getProductComponents`,
        PRODUCTS_CATEGORIES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getProductsCategories`,
        PRODUCT_TYPES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getProductTypes`,
        SCHEDULE_POST_SUCCESS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getSchedulePostSuccess`,
        SOURCES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getSources`,
        SOURCE_TYPES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getSourceTypes`,
        STATES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getStates`,
        STATUS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getStatus`,
        TAX_IDS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getTaxIds`,
        TRANSACTION_TYPES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getTransactionTypes`,
        USER_ROLES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getUserRoles`,
        WEEKLY_OFFS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getWeeklyOffs`,
        APPLICATION_ROLES: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/geApplicationRoles`,
        PROVIDERS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DROPDOWN}/getProviders`
    },
    DATA_FROM_PMS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_FROM_PMS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_FROM_PMS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_FROM_PMS}/delete`,
    },
    WRITE_TO_PMS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.WRITE_TO_PMS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.WRITE_TO_PMS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.WRITE_TO_PMS}/delete`,
    },
    CLIENT: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLIENT}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLIENT}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLIENT}/delete`,
    },
    PRACTICE_LOCATION: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRACTICE_LOCATION}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRACTICE_LOCATION}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRACTICE_LOCATION}/delete`,
    },
    PRACTICE_USER: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRACTICE_USER}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRACTICE_USER}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRACTICE_USER}/delete`,
    },
    DATABASE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATABASE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATABASE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATABASE}/delete`,
    },
    DATA_SOURCE_CONNECTION: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_SOURCE_CONNECTION}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_SOURCE_CONNECTION}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_SOURCE_CONNECTION}/delete`,
    },
    APPLICATION_ROLE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.APPLICATION_ROLE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.APPLICATION_ROLE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.APPLICATION_ROLE}/delete`,
    },
    USER_ROLE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USER_ROLE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USER_ROLE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USER_ROLE}/delete`,
    },
    MENUS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.MENUS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.MENUS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.MENUS}/delete`,
    },
    USERS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USERS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USERS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.USERS}/delete`,
    },
    SOURCE_TYPE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_TYPE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_TYPE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_TYPE}/delete`,
    },
    SOURCE_MASTER: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_MASTER}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_MASTER}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_MASTER}/delete`,
    },
    SOURCE_VERSION: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_VERSION}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_VERSION}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_VERSION}/delete`,
    },
    PAYOR_MASTER: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_MASTER}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_MASTER}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_MASTER}/delete`,
    },
    PAYOR_CREDENTIALS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_CREDENTIALS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_CREDENTIALS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_CREDENTIALS}/delete`,
    },
    PAYOR_TAG_MAPPING: {
        MAPPED_PAYORS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_TAG_MAPPING}/getAllMappedPayors`,
        PMS_PAYORS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_TAG_MAPPING}/getAllPMSPayors`,
        PAYORS: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_TAG_MAPPING}/getAllPayors`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PAYOR_TAG_MAPPING}/save`,
    },
    CLEARING_HOUSE_MASTER: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_MASTER}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_MASTER}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_MASTER}/delete`,
    },
    CLEARING_HOUSE_CREDENTIALS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_CREDENTIALS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_CREDENTIALS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_CREDENTIALS}/delete`,
    },
    CLEARING_HOUSE_MAPPING: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_TAG_MAPPING}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_TAG_MAPPING}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CLEARING_HOUSE_TAG_MAPPING}/delete`,
    },
    DATA_SOURCE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_SOURCE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_SOURCE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_SOURCE}/delete`,
    },
    DATA_CONTEXT: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_CONTEXT}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_CONTEXT}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_CONTEXT}/delete`,
    },
    CHILD_DATA_CONTEXT_MAPPING: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CHILD_DATA_CONTEXT_MAPPING}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CHILD_DATA_CONTEXT_MAPPING}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CHILD_DATA_CONTEXT_MAPPING}/delete`,
    },
    PHI: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PHI}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PHI}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PHI}/delete`,
    },
    SOURCE_FIELD: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_FIELD}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_FIELD}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SOURCE_FIELD}/delete`,
    },
    DESTINATION_FIELDS: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DESTINATION_FIELDS}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DESTINATION_FIELDS}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DESTINATION_FIELDS}/delete`,
    },
    COLUMN_MAPPING: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.COLUMN_MAPPING}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.COLUMN_MAPPING}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.COLUMN_MAPPING}/delete`,
    },
    DATA_EXTRACTION_FREQUENCY: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_EXTRACTION_FREQUENCY}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_EXTRACTION_FREQUENCY}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.DATA_EXTRACTION_FREQUENCY}/delete`,
    },
    EXTRACTION_SCHEDULE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.EXTRACTION_SCHEDULE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.EXTRACTION_SCHEDULE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.EXTRACTION_SCHEDULE}/delete`,
    },
    STATE: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.STATE}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.STATE}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.STATE}/delete`,
    },
    TAX_ID_MASTER: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TAX_ID_MASTER}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TAX_ID_MASTER}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TAX_ID_MASTER}/delete`,
    },
    TAX_ID_USER_MAPPING: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TAX_ID_USER_MAPPING}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TAX_ID_USER_MAPPING}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TAX_ID_USER_MAPPING}/delete`,
    },
    CONFIGURATION: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CONFIGURATION}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CONFIGURATION}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.CONFIGURATION}/delete`,
    },
    EXTRACTOR: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.EXTRACTOR}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.EXTRACTOR}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.EXTRACTOR}/delete`,
    },
    WRITE_BACK: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.WRITE_BACK}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.WRITE_BACK}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.WRITE_BACK}/delete`,
    },
    PRODUCT_CATEGORY: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_CATEGORY}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_CATEGORY}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_CATEGORY}/delete`,
    },
    PRODUCT_COMPONENT: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_COMPONENT}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_COMPONENT}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_COMPONENT}/delete`,
    },
    PRODUCT_SUBSCRIPTION: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_SUBSCRIPTION}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_SUBSCRIPTION}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.PRODUCT_SUBSCRIPTION}/delete`,
    },
    TARIFF: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TARIFF}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TARIFF}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.TARIFF}/delete`,
    },
    SCHEDULER: {
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SCHEDULER}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SCHEDULER}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.SCHEDULER}/delete`,
    },
    BAA: {
        GET: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.BAA}/getById`,
        GET_ALL: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.BAA}/getAll`,
        SAVE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.BAA}/save`,
        DELETE: `${ENDPOINT.GENERAL_SERVICE}/${CONTROLLER.BAA}/delete`,
    },
    ELIGIBILITY: {
        DETAILS: {
            GET_ALL: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/GetVerificationData`,
            GET_ALL_V2: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/GetVerificationDataV2`,
            GET_ALL_V3: `${ENDPOINT.ELIGIBILITY_SERVICE}/verification`,
            GET_ALL_ID: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/verification-by-id`,
            BULK_ACTION: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/BulkAssignToAgentOrReverify`,
            GET_VERIFICATION_PDF: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/DownloadVerification`,
            GET_AGENTS: `${ENDPOINT.ELIGIBILITY_SERVICE}/user/clinic-users`,
            ASSIGN_AGENT: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/agent/assign`,
            EXPORT: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/ExportData`,
            REVERIFY: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/reverify`,
            GET_STATUS_COUNT: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/GetStatusCount`,
            GET_PREFERENCE: `${ENDPOINT.ELIGIBILITY_SERVICE}/settings/preferences`,
            DOWNLOAD_PDF: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/DownloadVerification`,
            GET_FILTER_OPTIONS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.COMMON}/filter-options`,
            GET_FILTER_VALUES: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.COMMON}/filter-value`,
            GET_SCHEDULER_DATA: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.VERIFICATION}/scheduler-view`,
        },
        MANUAL_VERIFICATION: {
            GET: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.MANUAL_VERIFICATION}/GetVerificationData`,
            SAVE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.MANUAL_VERIFICATION}/SaveVerificationDataBySpeciality`,
            DOWNLOAD: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.MANUAL_VERIFICATION}/DownloadDocument`,
            GET_FILES: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.FILE}/DownloadFile`,
            DELETE_FILE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.FILE}/DeleteFile`,
            UPLOAD_FILE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.FILE}/SaveFile`,
            OPEN_FILE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.FILE}/OpenFile`
        },
        TEMPLATES: {
            GET: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetClientTemplates`,
            DELETE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/DeleteClientTemplateMapping`,
            GET_FIELD_MASTER: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetFieldsMaster`,
            GET_SECTION_MAPPING: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetSectionMappings`,
            GET_EXISTING_TEMPLATE_FIELDS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/getExistingTemplateFields`,
            GET_CLIENT_TEMPLATES: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetClientTemplates`,
            GET_PROC_CODE_MASTER: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetProcCodesMaster`,
            SAVE_PROC_CODE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/SaveProcCode`,
            GET_TEMPLATE_MASTER: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetTemplateMaster`,
            SAVE_CLIENT_TEMPLATE_MAPPING: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/SaveClientTemplateMapping`,
            GET_CLIENT_TEMPLATE_MAPPING: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetClientTemplateMapping`,
            GET_MASTER_TEMPLATE_MAPPING: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/GetMasterTemplateMapping`,
            DELETE_CLIENT_TEMPLATE_MAPPING: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.TEMPLATE}/DeleteClientTemplateMapping`,


        },
        SETTINGS: {
            GET_ACCESS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.SETTINGS}/Access`,
            GET_SETTINGS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.SETTINGS}/all`,
            SAVE_SETTINGS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.SETTINGS}`,
            GET_SETTINGS_BY_ID: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.SETTINGS}`,

        }
    },
    ON_DEMAND: {
        VERIFY_PATIENTS: `${ENDPOINT.ELIGIBILITY_SERVICE}/Eligibility`,
        SEARCH: `${ENDPOINT.ELIGIBILITY_SERVICE}/patient`,
        CLINIC: `${ENDPOINT.ELIGIBILITY_SERVICE}/Clinic`,
        PAYOR_BY_INSURANCE: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.COMMON}/GetPayorInfoByNameAsync`,
        PROVIDERS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.COMMON}/GetProviders`,
        PROCESS_EXCEL_RECORDS: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.ELIGIBILITY}/process-excel-records`,
        GET_PROVIDER_ID: `${ENDPOINT.ELIGIBILITY_SERVICE}/${CONTROLLER.ELIGIBILITY}/getproviderids`
    },
    RCM_DASHBOARD:{
        GET_APPOINTMENT_VERIFIED_BY_BOT_AND_TOTAL_APPOINTMENT_VERIFIED:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetAppointmentVerifiedByBotAndTotalAppointmentVerified`,
        GET_APPOINTMENT_BY_CURRENT_STATUS:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetAppointmentByCurrentStatus`,
        GET_TOTAL_VERIFIED_AND_STATUS_COUNT:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetTotalVerifiedAndStatusCount`,
        GET_LOCATION_WISE_COUNT:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetLocationWiseCount`,
        GET_TOP_10_PAYORS_ELIGIBILITY_STATUS:`${ ENDPOINT.GENERAL_SERVICE }/${ CONTROLLER.RCM_DASHBOARD }/GetTop10PayorsEligibilityStatus`,
    },
    VOICE_BOT:{
        INSERT_VERIFICATION_DATA:`${ ENDPOINT.ELIGIBILITY_SERVICE }/${ CONTROLLER.VOICE }`,
    }
};
