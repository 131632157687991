// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  isPMSWriteBackTestingMode: false,
  production: false,
  appVersion: 'v0.0.1',
  sessionKey: 'vRN9DBUkIF',
  menuKey: 'da-menu',
  logOutUrl: 'https://eligibility.dev.dentistryautomation.com',
  baseUrl: 'https://eligibility.dev.dentistryautomation.com',
  // baseUrl: 'https://localhost:4200',
  identityServiceUrl: 'https://login-api.dev.dentistryautomation.com/',
  dentaFlowUrl: 'https://coreapi.dev.dentistryautomation.com',
  productApiUrl: 'https://eligibility-api.dev.dentistryautomation.com/api',
  // productApiUrl: 'https://localhost:43353/api',
  voiceBotAcessIds:['9a1a847a-ff67-4907-8fe0-0d72ee426e7c'],
  voiceApiUrl:'https://payorvoice-api.dev.dentistryautomation.com',
  instrumentationKey:'fb20a55a-0fc2-4121-be4f-07d49498b69d',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
