import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, subscribeOn } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Products } from 'src/app/core/constant/product.constants';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';
import { API } from 'src/app/core/constant/api.constants';
import { jwtDecode} from "jwt-decode";
import { DatePipe } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    count: number;
    constructor(private authenticationService: AuthenticationService) { }
    datePipe= new DatePipe('en-Us');
    helper = new JwtHelperService();
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                'X-App-id': Products.EligibilityVerification.toString()
            },
        });

        const token = this.authenticationService.user.access_token;

        // const token = this.authenticationService.tokenValue;
        if (token) {

            // let currentDate= new Date()
            // const expirationDate = this.helper.getTokenExpirationDate(token.token);
            // let timestamp= (expirationDate.getTime()-currentDate.getTime())/60000
            // this.count = 0;

            // if (timestamp<5&&timestamp>0) {
            //     if (localStorage.getItem('refreshFlag')) {
                    
            //     }else{
            //         localStorage.setItem('refreshFlag','done')
            //         this.authenticationService.refreshToken(token).subscribe(res=>{});
            //     }
            // }else{
            //     localStorage.removeItem('refreshFlag')
            // }
    
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                },
            });
        }
        return next.handle(request);
    }
}
