import { UserProfile } from 'src/app/modules/user-profile/models/user.mode';
import { UserRoles } from './../../../../global-enum';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenData } from '../models/token-data.model';
import { API } from 'src/app/core/constant/api.constants';
import { ToastService } from 'src/app/shared/toast/services/toast-service';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';
import { PasswordSetup } from '../models/password-setup.model';
import { environment } from 'src/environments/environment';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { Token } from 'prismjs';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService
{
    private unsubscribe: Subscription[] = [];
    private _authNavStatusSource = new BehaviorSubject<boolean>(false);
    // Observable navItem stream
    authNavStatus$ = this._authNavStatusSource.asObservable();

    private manager = new UserManager(getClientSettings());
    public user: User | null;

    isLoading$: Observable<boolean>;
    isLoadingSubject: BehaviorSubject<boolean>;

    token$: Observable<TokenData>;
    tokenSubject: BehaviorSubject<TokenData>;

    get tokenValue(): TokenData
    {
        return this.tokenSubject.value;
    }

    set tokenValue(tokenData: TokenData)
    {
        this.tokenSubject.next(tokenData);
    }

    constructor(private http: HttpClient, private router: Router, private toastService: ToastService)
    {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.isLoading$ = this.isLoadingSubject.asObservable();
        this.tokenSubject = new BehaviorSubject<TokenData>(this.getAuthFromLocalStorage());
        this.token$ = this.tokenSubject.asObservable();
        this.manager.getUser().then(user => { 
            this.user = user;      
            this._authNavStatusSource.next(this.isAuthenticated());
        });
    }

    login() { 
        return this.manager.signinRedirect();   
    }

    async completeAuthentication() {
        this.user = await this.manager.signinRedirectCallback();
        this._authNavStatusSource.next(this.isAuthenticated());    
        this.setAuthFromLocalStorage(this.user);
        let key=Object.keys(this.user.profile)
        let roleValue = key.filter(item=>item.includes('role'))
        
        this.tokenSubject = new BehaviorSubject<TokenData>(this.user);
    }  

    // register(userRegistration: any) {    
    // return this.http.post(this.configService.authApiURI + '/account', userRegistration).pipe(catchError(this.handleError));
    // }

    isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
    }

    // get token(): string {
    //     return `${this.user.access_token}`;
    //     }

    get authorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
    }

    get name(): string {
    return this.user != null ? this.user.profile.name : '';
    }

    async signout() {
    await this.manager.signoutRedirect();
    }  


    refreshToken(passwordSetup: PasswordSetup): Observable<boolean>
    {
        this.isLoadingSubject.next(true);
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', `Bearer ${ passwordSetup.token }`);
        return this.http.post<any>(API.AUTH.REFRESH, passwordSetup,{
            headers: headers,
          })
            .pipe(
                map((res: any) =>
                {
                    const result = res;
                    if (res)
                    {
                        this.setAuthFromLocalStorage(res);
                        this.tokenSubject = new BehaviorSubject<TokenData>(res);
                    }
                    return result;
                }),
                // switchMap(() => this.getUserByToken() as any),
                catchError((err) =>
                {
                    // this.toastService.error(err);
                    return of(undefined);
                }),
                finalize(() => this.isLoadingSubject.next(false))
            );
    }

    logout()
    {
        localStorage.removeItem(LOCAL_STORAGE.TOKEN);
        localStorage.removeItem(LOCAL_STORAGE.USER);
        localStorage.removeItem('da-menu');
        localStorage.removeItem('All-Menu');
        localStorage.removeItem('gridRequest')
        localStorage.removeItem('usedNumberStartBot')
        localStorage.removeItem('filterValue')
        localStorage.removeItem('clinics')
        localStorage.removeItem('usedNumberStartCall')
        localStorage.removeItem('validate')
        localStorage.removeItem('pageUrl')
        this.tokenValue=null;
        window.location.replace('');
        this.manager.signoutRedirect();
        // window.location.replace(environment.logOutUrl+'?signIn=false');
        // window.open(environment.logOutUrl+'?signIn=false','_self');
    }

    getAuthFromLocalStorage(): TokenData
    {
        try
        {
            const authData = JSON.parse(localStorage.getItem(LOCAL_STORAGE.TOKEN));
            return authData;
        }
        catch (error)
        {
          
        }
        return null;
    }

    private setAuthFromLocalStorage(tokenData: TokenData): boolean
    {
        if (tokenData && tokenData.access_token)
        {
            localStorage.setItem(LOCAL_STORAGE.TOKEN, JSON.stringify(tokenData.access_token));
            return true;
        }
        return false;
    }

    ngOnDestroy()
    {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

}

export function getClientSettings(): UserManagerSettings{
    return{
        authority: environment.identityServiceUrl,
        client_id: 'eligibility',
        //redirect_uri: 'https://localhost:4200/auth-callback',
        redirect_uri: environment.baseUrl+'/signin-oidc',
        post_logout_redirect_uri : environment.baseUrl+'/signout-callback-oidc',
        response_type:"id_token token",
        scope:"openid profile dauniversal da",
        filterProtocolClaims: true,
        loadUserInfo: true,
    };
}