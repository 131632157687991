
import { Injectable } from '@angular/core';
import { Subject, Observable, filter } from 'rxjs';
import { ToastType } from '../models/enums/toast-type';
import { Toast } from '../models/toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {
    private subject = new Subject<Toast>();
    private defaultId = 'default-toast';

    // enable subscribing to alerts observable
    onToast(id = this.defaultId): Observable<Toast> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, options?: any, title: string = 'Success') {
        this.toast(new Toast({ ...options, type: ToastType.Success, title: title, icon: "check-circle", message }));
    }

    error(message: string, options?: any, title: string = 'Error', autoClose?: boolean) {
        this.toast(new Toast({ ...options, type: ToastType.Error, title: title, icon: "times-circle", message }));
    }

    info(message: string, options?: any, title: string = 'Info') {
        this.toast(new Toast({ ...options, type: ToastType.Info, title: title, icon: "info-circle", message }));
    }

    warn(message: string, options?: any, title: string = 'Warning') {
        this.toast(new Toast({ ...options, type: ToastType.Warning, title: title, icon: "exclamation-circle", message }));
    }

    toast(toast: Toast) {
        toast.id = toast.id || this.defaultId;
        this.subject.next(toast);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Toast({ id }));
    }

    //helping methods
    showConnectionError() {
        const options = { autoClose: false, keepAfterRouteChange: true };
        this.error("Connection with server lost", options);
    }

    generalError() {
        const options = { autoClose: false, keepAfterRouteChange: true };
        this.error("Oops, something is wrong please try again later", options);
    }

    // showAll(messages: ServerMessage[])
    // {
    //     messages.forEach(x =>
    //     {
    //         switch (x.level)
    //         {
    //             case MessageType.error:
    //                 this.error(x.text, {
    //                     autoClose: true,
    //                     keepAfterRouteChange: true
    //                 });
    //                 break;
    //             case MessageType.warning:
    //                 this.warn(x.text);
    //                 break;
    //             case MessageType.success:
    //                 this.success(x.text, {
    //                     autoClose: true,
    //                     keepAfterRouteChange: true
    //                 });
    //                 break;
    //             case MessageType.information:
    //                 this.info(x.text, {
    //                     autoClose: true,
    //                     keepAfterRouteChange: true
    //                 });
    //                 break;
    //         }
    //     });
    // }
}